import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@mui/material";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { BsFillEyeFill } from "react-icons/bs";
import styles from "./datatable.module.css";
import Button from "../Button/Button";
import AlertDialog from "../Dialog/Dialog";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import Input from "../Input/Input";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: any;
  format?: (value: number) => string;
}

interface Data {
  titleCode?: string;
  proprietorIsbn?: string;
  LBPLIsbn?: number;
  titleTmsId?: string;
  workingTitle?: string;
  productCategory?: string;
  author?: string;
  assignContractCode?: string;
  projectedDate?: string;
  name?: string;
  projectStatus?: string;
  id: string;
  projectName: string;
  title: string;
  identifier: string;
  authors: string;
}

interface DataTableProps {
  columns: Column[];
  rows: any;
  onEditHandler?: (arg: number | string, arg2: number) => void;
  onDeleteHandler?: (arg: number | string, arg2: number) => void;
  onViewHandler?: (arg: number | string) => void;
  onCreateHandler?: (arg: number | string, arg2: any) => void;
  checkBoxHandling?: (arg: number[] | string[]) => void;
  sortingHandler?: () => void;
  searchColumn?: string;
  showDelete?: boolean;
  showEdit?: boolean;
  showView?: boolean;
  checkBoxShow?: boolean;
  tableHeading?: string;
  showNew?: boolean;
  showDownload?: boolean;
  sortColumn?: boolean;
  rowsPerPage: number;
  totalRowsCount: number;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  handleRowsPerPageChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  page: number;
  strSortOrder?: string;
  sortColumns?: string[];
  checkBoxValue?: string;
  sortingHandling: (arg: string, sortOrder: string) => void;
  primaryKey?: string;
  onDownloadHandler?: (arg: any) => void;
}

const DataTable = (props: DataTableProps) => {
  const [open, setOpen] = useState(false);
  const [handlerId, setHandlerId] = useState({
    id: 0,
    index: -1,
  });
  const [columnId, setColumnId] = useState("");
  const [isSelected, setIsSelected] = useState<any>([]);
  const {
    columns,
    rows,
    onEditHandler,
    onDeleteHandler,
    onViewHandler,
    showDelete,
    showEdit,
    checkBoxShow,
    showView,
    tableHeading,
    showNew,
    showDownload,
    onCreateHandler,
    sortingHandling,
    checkBoxHandling,
    primaryKey,
    sortColumn,
    totalRowsCount,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    strSortOrder,
    sortColumns,
    checkBoxValue,
    onDownloadHandler,
  } = props;

  const [data, setData] = useState(rows);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  const handleDeleteConfirmationOpen = (id: number, index: number) => {
    setOpen(true);
    setHandlerId({ ...handlerId, id: id, index: index });
  };

  const handleDeleteConfirmationClose = () => {
    setOpen(false);
  };

  const onSubmitClick = () => {
    onDeleteHandler?.(handlerId.id, handlerId.index);
    setOpen(false);
  };

  const sorting = (column_Id: string, sortOrder: string) => {
    setColumnId(column_Id);
    sortingHandling(column_Id, sortOrder);
  };

  const tableheaderLableHandling = (columnObj: Column, lindex: number) => {
    if (
      sortColumns?.includes(columnObj.label) ||
      (sortColumns?.includes("All") && columnObj.label !== "Action")
    ) {
      return (
        <div className="w-fit flex items-center">
          <div className="mr-1">{columnObj.label}</div>
          {sortColumn || sortColumn === undefined ? (
            <>
              {strSortOrder === "DESC" && columnId == columnObj.id ? (
                <AiOutlineArrowUp
                  color="white"
                  onClick={() => sorting(columnObj.id, "DESC")}
                />
              ) : (
                <AiOutlineArrowDown
                  color="white"
                  onClick={() => sorting(columnObj.id, "ASC")}
                />
              )}
            </>
          ) : null}
        </div>
      );
    } else {
      return <div className="mr-1">{columnObj.label}</div>;
    }
  };

  const handleCheckingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      const tempUser = data.map((item: any) => {
        return { ...item, isChecked: checked };
      });
      const selectedItems = tempUser
        .filter((data: any) => data.isChecked === true)
        .map((data: any) => data[`${checkBoxValue}`]);

      setIsSelected(selectedItems);
      setData(tempUser);
      console.log("AllSelect", selectedItems);
      checkBoxHandling?.(selectedItems);
    } else {
      const tempUser = data.map((item: any) =>
        item[`${checkBoxValue}`] === Number(name) ||
        item[`${checkBoxValue}`] === name
          ? { ...item, isChecked: checked }
          : item
      );
      const selectedItems = tempUser
        .filter((data: any) => data.isChecked === true)
        .map((data: any) => data[`${checkBoxValue}`]);
      setIsSelected(selectedItems);
      setData(tempUser);
      console.log("SingleSelect", selectedItems);
      checkBoxHandling?.(selectedItems);
    }
  };

  const confrmationDialog = () => {
    return (
      <div className="p-5">
        <div className="font-bold text-xl">Confirmation Window</div>
        <div className="py-4 text-md text-[gray]">
          Are you sure want to delete this Item?
        </div>
        <div className="flex justify-end gap-4">
          <Button
            title={"Submit"}
            color={"primary"}
            type={"button"}
            onClick={() => onSubmitClick()}
          />
          <Button
            title={"Cancel"}
            color={"danger"}
            type={"button"}
            onClick={() => handleDeleteConfirmationClose()}
          />
        </div>
      </div>
    );
  };

  const showItem = (
    value: string,
    columnname: string,
    id: number,
    index: number,
    row: any
  ) => {
    if (columnname === "action") {
      return (
        <div className="flex items-center">
          {showView ? (
            <BsFillEyeFill
              onClick={() => onViewHandler?.(id)}
              color="green"
              className=" w-6 h-6 p-1 rounded-sm cursor-pointer mr-1"
            />
          ) : null}

          {showEdit ? (
            <FaPencilAlt
              onClick={() => onEditHandler?.(id, index)}
              color="blue"
              className=" w-6 h-6 p-1 rounded-sm cursor-pointer mr-1"
            />
          ) : null}

          {showDelete ? (
            <FaTrashAlt
              onClick={() => handleDeleteConfirmationOpen(id, index)}
              color="red"
              className=" w-6 h-6 p-1 rounded-sm cursor-pointer"
            />
          ) : null}
          {showNew ? (
            <Button
              title={"New"}
              color={"primary"}
              type={"button"}
              onClick={() => onCreateHandler?.(id, row)}
            />
          ) : null}
          {showDownload ? (
            <div className="cursor-pointer" onClick={() => onDownloadHandler?.(row)}>
              <i className="fa-solid fa-cloud-arrow-down text-lg"></i>
            </div>
          ) : null}
        </div>
      );
    } else if (columnname === "licenseCode") {
      return <Input width={"w-[100px]"} />;
    } else {
      return value;
    }
  };

  return (
    <>
      <div className="flex items-center font-semibold text-[18px] pb-1">
        {tableHeading}
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table" align="left" stickyHeader>
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow>
                {checkBoxShow ? (
                  <TableCell classes={{ root: styles.tableCell }}>
                    <Checkbox
                      classes={{ root: styles.checkboxhead }}
                      color="info"
                      name="allSelect"
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCheckingChange(e)
                      }
                      checked={!data.some((user: any) => user?.isChecked !== true)}
                      size="small"
                    />
                  </TableCell>
                ) : null}

                {columns.map((column: Column, index: number) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    classes={{ root: styles.tableHeadCell }}>
                    {tableheaderLableHandling(column, index)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, index: number) => {
                console.log("RowName", row[`${checkBoxValue}`]);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {checkBoxShow ? (
                      <TableCell
                        align="left"
                        classes={
                          row?.isChecked
                            ? { root: styles.tablebodyWithChecked }
                            : { root: styles.tablebody }
                        }>
                        <Checkbox
                          checked={row?.isChecked || false}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCheckingChange(e)
                          }
                          name={row[`${checkBoxValue}`]}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column: Column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.label}
                          align={column.align}
                          classes={
                            row?.isChecked
                              ? { root: styles.tablebodyWithChecked }
                              : { root: styles.tablebody }
                          }>
                          <div className="py-1">
                            {showItem(
                              value,
                              column.id,
                              row[`${primaryKey}`],
                              index,
                              row
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {totalRowsCount > 20 && (
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalRowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
        <div>
          {open ? (
            <AlertDialog open={open} handleClose={handleDeleteConfirmationClose}>
              {confrmationDialog()}
            </AlertDialog>
          ) : null}
        </div>
      </Paper>
    </>
  );
};
export default DataTable;
