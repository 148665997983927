// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VyuUi0J_ogdxdz61a9Gj {\r\n  background-color: #38738a;\r\n  z-index: -1;\r\n}\r\n\r\n.riGCVKsIZnqD6X4xfS91 {\r\n  background-color: #38738a;\r\n  padding: 10px;\r\n}\r\n\r\n.BIRLzZU1o4EoA3Evp0N1 {\r\n  color: white;\r\n  background-color: #38738a;\r\n  font-size: 14px;\r\n  padding: 10px;\r\n  padding-left: 10px;\r\n  font-weight: 550;\r\n  z-index: 5;\r\n  cursor: pointer;\r\n}\r\n.GnL9YQKOBs4S6YBurG6O {\r\n  font-size: 13px;\r\n  color: rgb(71, 54, 97);\r\n  padding: 8px;\r\n  padding-left: 10px;\r\n  font-weight: 600;\r\n}\r\n.NA2nIew3SYODyX5ljBSi {\r\n  font-size: 14px;\r\n  color: grey;\r\n  padding: 8px;\r\n  padding-left: 10px;\r\n  background-color: #f2f5f5;\r\n}\r\n.pmTGbDO2GIx1CAIG6wPk {\r\n  color: white;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/DataTable/datatable.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,eAAe;AACjB;AACA;EACE,eAAe;EACf,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd","sourcesContent":[".tableHead {\r\n  background-color: #38738a;\r\n  z-index: -1;\r\n}\r\n\r\n.tableCell {\r\n  background-color: #38738a;\r\n  padding: 10px;\r\n}\r\n\r\n.tableHeadCell {\r\n  color: white;\r\n  background-color: #38738a;\r\n  font-size: 14px;\r\n  padding: 10px;\r\n  padding-left: 10px;\r\n  font-weight: 550;\r\n  z-index: 5;\r\n  cursor: pointer;\r\n}\r\n.tablebody {\r\n  font-size: 13px;\r\n  color: rgb(71, 54, 97);\r\n  padding: 8px;\r\n  padding-left: 10px;\r\n  font-weight: 600;\r\n}\r\n.tablebodyWithChecked {\r\n  font-size: 14px;\r\n  color: grey;\r\n  padding: 8px;\r\n  padding-left: 10px;\r\n  background-color: #f2f5f5;\r\n}\r\n.checkboxhead {\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHead": "VyuUi0J_ogdxdz61a9Gj",
	"tableCell": "riGCVKsIZnqD6X4xfS91",
	"tableHeadCell": "BIRLzZU1o4EoA3Evp0N1",
	"tablebody": "GnL9YQKOBs4S6YBurG6O",
	"tablebodyWithChecked": "NA2nIew3SYODyX5ljBSi",
	"checkboxhead": "pmTGbDO2GIx1CAIG6wPk"
};
export default ___CSS_LOADER_EXPORT___;
