// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RFRxWMmIisC5VXQLyKmW {\r\n    font-size: 10px;\r\n}\r\n.PMkI5x5bV3KPVmcLA54w::before {\r\n    content: \"\";\r\n    display: block;\r\n    position: absolute;\r\n    top: -8px;\r\n    left: 50%;\r\n    -webkit-transform: rotate(45deg);\r\n            transform: rotate(45deg);\r\n    border: 6px solid #1f1458;\r\n    border-top-width: transparent;\r\n    border-bottom-color: transparent;\r\n    border-right-color: transparent;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Calender/Calender.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,gCAAwB;YAAxB,wBAAwB;IACxB,yBAAyB;IACzB,6BAA6B;IAC7B,gCAAgC;IAChC,+BAA+B;AACnC","sourcesContent":[".calenderfs {\r\n    font-size: 10px;\r\n}\r\n.calenderContainer::before {\r\n    content: \"\";\r\n    display: block;\r\n    position: absolute;\r\n    top: -8px;\r\n    left: 50%;\r\n    transform: rotate(45deg);\r\n    border: 6px solid #1f1458;\r\n    border-top-width: transparent;\r\n    border-bottom-color: transparent;\r\n    border-right-color: transparent;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calenderfs": "RFRxWMmIisC5VXQLyKmW",
	"calenderContainer": "PMkI5x5bV3KPVmcLA54w"
};
export default ___CSS_LOADER_EXPORT___;
