import React from "react";
import { useFormik } from "formik";
import Input from "../components/Input/Input";
import Dropdown from "../components/Dropdown/Dropdown";
import Button from "../components/Button/Button";

const FormIK = () => {
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }
    if (!values.gender) {
      errors.gender = "Gender is Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <div className="bg-white shadow-lg py-4 px-8">
      <div className="font-bold text-[26px] text-[#022969] font-sans">
        Create New Title
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className=" flex justify-between">
          <div className="w-1/2">
            <Input
              onChange={formik.handleChange}
              label={"First Name"}
              name="firstName"
              type="text"
              value={formik.values.firstName}
              errors={formik.errors.firstName}
            />
            <Input
              onChange={formik.handleChange}
              label={"Last Name"}
              name="lastName"
              type="text"
              value={formik.values.lastName}
              errors={formik.errors.lastName}
            />

            <Dropdown
              onChange={formik.handleChange}
              label={"Gender"}
              name="gender"
              value={formik.values.gender}
              errors={formik.errors.gender}
            />
          </div>
          <div className="w-1/2">
            <Input
              onChange={formik.handleChange}
              label={"First Name"}
              name="firstName"
              type="text"
              value={formik.values.firstName}
              errors={formik.errors.firstName}
            />
            <Input
              onChange={formik.handleChange}
              label={"Last Name"}
              name="lastName"
              type="text"
              value={formik.values.lastName}
              errors={formik.errors.lastName}
            />
          </div>
        </div>
        <div className="ml-2">
          <Button title={"Submit"} type={"submit"} color={"primary"} />
        </div>
      </form>
    </div>
  );
};

export default FormIK;
