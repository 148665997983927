import axios from "axios";
import { store } from "../redux/store";
import { update_refreshToken, update_token } from "../redux/action";

let refreshPromise: Promise<any> | null = null;
const clearPromise = () => (refreshPromise = null);

const instance = axios.create({
  baseURL: "http://192.168.5.166/LCRAAPI/api",
});

const refreshToken = async () => {
  const response = await instance.post("/refreshToken", {
    accessToken: store.getState().token,
    refreshToken: store.getState().refreshToken,
  });
  store.dispatch(update_token(response?.data?.accessToken));
  store.dispatch(update_refreshToken(response?.data?.refreshToken?.token));
  return response.data.accessToken;
};

instance.interceptors.request.use(function (config) {
  const token = store.getState().token;
  config.headers.Authorization = token ? `Bearer ${token}` : "No token";
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        if (!refreshPromise) {
          refreshPromise = refreshToken().finally(clearPromise);
        }

        const token = await refreshPromise;
        originalConfig.headers.Authorization = `Bearer ${token}`;

        return instance(originalConfig);
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
