import React, { useEffect, useState } from "react";
import { Checkbox, ClickAwayListener } from "@mui/material";
import SelectProps, { OptionProps } from "./Select.props";

const Select = (props: SelectProps) => {
  const { options, isMulti, onSelectHandler, width, defaultvalue } = props;
  const [filterText, setFilterText] = useState<string>("");
  const [selectedMenu, setSelectedMenu] = useState<string>("");
  const [showDropdownList, setShowDropdownList] = useState<boolean>(false);
  const [optionArray, setOptionArray] = useState<OptionProps[]>([]);
  const [seletedMultipleMenu, setSeletedMultipleMenu] = useState<Array<string>>([]);
  const [seletedMultipleMenuIds, setSeletedMultipleMenuIds] = useState<
    Array<number>
  >([]);

  useEffect(() => {
    options?.length && setOptionArray([...options]);
  }, [options]);

  useEffect(() => {
    if (defaultvalue && isMulti) {
      const defaultValueArray = [...(defaultvalue as OptionProps[])].map(
        (item) => item.name
      );
      const defaultValueArrayIds = [...(defaultvalue as OptionProps[])].map(
        (item) => item.id
      );
      setSelectedMenu(defaultValueArray.join(","));
      setSeletedMultipleMenu([...defaultValueArray]);
      setSeletedMultipleMenuIds([...defaultValueArrayIds]);
    } else if (defaultvalue && !isMulti) {
      setSelectedMenu(defaultvalue as string);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAway = () => {
    setShowDropdownList(false);
  };

  const handleShowDropdownList = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event) {
      if (event.key === "Tab") {
        return;
      }
      event.key === "Enter" && setShowDropdownList(true);
    }
    if (showDropdownList) {
      setShowDropdownList(false);
      setFilterText("");
      setOptionArray([...options]);
    } else {
      setShowDropdownList(true);
    }
  };

  const filterSelectList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
    setOptionArray([
      ...options.filter((item) =>
        item.name.includes(event.target.value) ? item : null
      ),
    ]);
  };

  const filterClear = () => {
    setFilterText("");
    setOptionArray([...options]);
  };

  const onSelectList = (
    item: OptionProps,
    event?: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (isMulti) {
      if (event) {
        if (event.key === "Enter") {
          setFilterText("");
          if (
            seletedMultipleMenu.includes(item.name) &&
            seletedMultipleMenuIds.includes(item.id)
          ) {
            const slicedMenuList = seletedMultipleMenu.filter(
              (data) => data !== item.name
            );
            const slicedMenuListIds = seletedMultipleMenuIds.filter(
              (data) => data !== item.id
            );
            setSeletedMultipleMenu([...slicedMenuList]);
            setSeletedMultipleMenuIds([...slicedMenuListIds]);
            setSelectedMenu(slicedMenuList.join());
            onSelectHandler([...slicedMenuListIds]);
          } else {
            const selectedMenuList = [...seletedMultipleMenu];
            const selectMenuIds = [...seletedMultipleMenuIds];
            selectedMenuList.push(item.name);
            selectMenuIds.push(item.id);
            setSeletedMultipleMenu([...selectedMenuList]);
            setSeletedMultipleMenuIds([...selectMenuIds]);
            setSelectedMenu(selectedMenuList.join());
            onSelectHandler([...selectMenuIds]);
          }
        }
      } else {
        if (
          seletedMultipleMenu.includes(item.name) &&
          seletedMultipleMenuIds.includes(item.id)
        ) {
          const slicedMenuList = seletedMultipleMenu.filter(
            (data) => data !== item.name
          );
          const slicedMenuListIds = seletedMultipleMenuIds.filter(
            (data) => data !== item.id
          );
          setSeletedMultipleMenu([...slicedMenuList]);
          setSeletedMultipleMenuIds([...slicedMenuListIds]);
          setSelectedMenu(slicedMenuList.join());
          onSelectHandler([...slicedMenuListIds]);
        } else {
          const selectedMenuList = [...seletedMultipleMenu];
          const selectMenuIds = [...seletedMultipleMenuIds];
          selectedMenuList.push(item.name);
          selectMenuIds.push(item.id);
          setSeletedMultipleMenu([...selectedMenuList]);
          setSeletedMultipleMenuIds([...selectMenuIds]);
          setSelectedMenu(selectedMenuList.join());
          onSelectHandler([...selectMenuIds]);
        }
      }
    } else {
      if (event) {
        if (event.key === "Enter") {
          setSelectedMenu(item.name);
          setShowDropdownList(false);
          setFilterText("");
          setOptionArray([...options]);
          onSelectHandler(item);
        }
      } else {
        setSelectedMenu(item.name);
        setShowDropdownList(false);
        setFilterText("");
        setOptionArray([...options]);
        onSelectHandler(item);
      }
    }
  };

  return (
    <div className="select-wrapper">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={`relative select-header min-w-[300px] ${
            width && width
          } bg-white`}>
          <div
            className="select-title-block flex items-center justify-between px-2 py-1 border border-[#e5e7eb] outline-none cursor-pointer rounded-sm"
            onClick={() => handleShowDropdownList()}
            tabIndex={0}
            onKeyDown={(event) => handleShowDropdownList(event)}>
            <div className="select-title w-11/12 text-sm">{selectedMenu}</div>
            <div className="select-dropdown-icon mr-1">
              {!showDropdownList ? (
                <i className="fa-solid fa-caret-down"></i>
              ) : (
                <i className="fa-solid fa-caret-up"></i>
              )}
            </div>
          </div>
          {showDropdownList && (
            <div className="select-list absolute left-0 shadow-2xl w-full">
              <div
                className={`select-search-block ${
                  options?.length && !isMulti ? "p-2" : "p-0"
                } bg-gray-200`}>
                {options?.length > 0 && !isMulti ? (
                  <div className="relative">
                    <input
                      type="text"
                      className="px-3 py-1 border border-transparent border-solid bg-white outline-none rounded-md w-full"
                      placeholder="search"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        filterSelectList(event)
                      }
                      value={filterText}
                    />
                    {filterText.length > 0 && (
                      <div
                        className="absolute right-2 top-1 cursor-pointer"
                        tabIndex={0}
                        onClick={filterClear}>
                        <i className="fa-solid fa-circle-xmark text-gray-400"></i>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div
                className="select-list-block bg-white min-h-52 max-h-52 overflow-y-auto z-50 relative"
                id="select-list">
                {options?.length > 0 && optionArray?.length === 0 && (
                  <div className="select-list-item px-5 py-2 cursor-pointer">
                    No Match Found
                  </div>
                )}
                {options?.length === 0 && optionArray?.length === 0 && (
                  <div className="select-list-item px-5 py-2 cursor-pointer">
                    No Data
                  </div>
                )}
                {optionArray?.map((item) => (
                  <div
                    className={`select-list-item flex px-5 py-2 cursor-pointer hover:bg-[#cccccca8] ${
                      item.name.includes(selectedMenu) &&
                      !isMulti &&
                      "bg-[#cccccca8]"
                    }`}
                    key={item.id}
                    tabIndex={0}
                    onClick={() => onSelectList(item)}
                    onKeyDown={(event) => onSelectList(item, event)}>
                    {isMulti && (
                      <div>
                        <Checkbox
                          className="mr-3"
                          sx={{
                            padding: 0,
                          }}
                          size="small"
                          checked={seletedMultipleMenu?.includes(item?.name)}
                        />
                      </div>
                    )}
                    <div>{item.name}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Select;
