interface ButtonProps {
  type: "button" | "submit" | "reset" | undefined;
  title: string | JSX.Element;
  color: string;
  onClick?: () => void;
  height?: string;
}
const Button = (props: ButtonProps) => {
  const { type, title, onClick, color, height } = props;

  const buttonBackground = () => {
    switch (color) {
      case "primary":
        return "bg-[#0047c7]";
      case "danger":
        return "bg-red-600";
      case "normal":
        return "bg-slate-600";
      default:
        return "bg-sky-600";
    }
  };
  return (
    <button
      data-testid="button-wrapper"
      type={type}
      onClick={onClick}
      className={`${buttonBackground()} ${height} text-white flex items-center justify-center shadow-md uppercase rounded-sm text-sm py-2 px-4 cursor-pointer hover:bg-[#ff5f00] duration-300`}>
      <div className="text-sm font-semibold" data-testid="button-title">
        {title}
      </div>
    </button>
  );
};

export default Button;
